<template lang="pug">
div
  l-marker(
    v-if="selectedPnt"
    :lat-lng="selectedPnt"
    :icon="icon"
    @ready="markerMixinReady"
    ref="mixinMarker")
  //- BOX EN MODO RESPONSIVE PARA DESKTOP
  l-control.control-container.bgLeafletControl.hidden-md-and-down(
    :style="{width: showTip === true ? 'map-control-opened' : 'map-control-closed'}"
    position="topright")
    .control-icon.py-1.px-1(
      v-if="!showTip",
      @click="toggleTip",
      style="cursor: pointer"
      title="Dibujar diagrama skewT"
    )
      v-icon(large) mdi-chart-box-outline
    .control-tip.hidden-md-and-down(v-else)
      .text-h5.mb-1
        | Sondeos
        //v-chip(
          v-if="currentAppRate.rate_id === 0 || currentAppRate.price === 0"
          class="pa-1 float-right"
          color="green"
          :title="premiumTitle"
          @click="goPayment"
          outlined)
          v-icon mdi-cart-arrow-down
          |<b>Comprar</b>
        v-btn(
          small
          icon="mdi-chevron-right"
          variant="text"
          elevation="0"
          density="comfortable"
          class="text-center float-right pa-2 my-1"
          @click="cancel")
          v-icon(large) mdi-chevron-double-right
      v-divider
      .my-2.control-internal-content
        | Seleccione un punto en el mapa para desplegar el sondeo
      v-divider(v-if="selectedPnt")
      .my-2(v-if="selectedPnt")
        div(class="text-subtitle-1 text--subtitleLeafletControl font-weight-light")
          | Punto seleccionado
        div {{selectedPnt.lat}} | {{selectedPnt.lng}}
      //- v-switch(
        class="mt-0"
        @click="switch = !switch"
        color="orange darken-3"
        title="Cambiar tipo de gráfica")
      forecast-variables(ref="variablesControl" class="variables-sondeos pt-0 pb-0")
      v-select(
        v-model="selectedDiagram"
        class="control-internal-content"
        small
        :items="diagrams"
        label="Seleccione tipo de gráfica"
        outlined
        dense)
      v-tooltip(top nudge-bottom="80")
        template(v-slot:activator="{ on, attrs }")
          v-btn(
            v-if="currentAppRate.rate_id === 0 || currentAppRate.price === 0"
            rounded
            class="text-none text-subtitle-1 premium-button-lg"
            variant="elevated"
            color="white"
            @click="goPayment"
            outlined
            v-bind="attrs"
            v-on="on")
            v-icon mdi-medal
            <b>Plan Premium</b>
        span {{premiumTitle}}
      v-divider(class="mt-3")
      span(v-if="errorMsg" class="mt-2 errorLefletControl--text control-error") {{errorMsg}}
      .mt-2.d-flex.control-internal-content
        v-row
          v-col(cols="12" md="6")
            v-btn(
              block
              small
              color="#00B0FF"
              class="text-subtitle-2 boton_control"
              @click="createSounding") Graficar
              v-icon(right dark small) mdi-chart-areaspline
      //- BOX EN MODO RESPONSIVE PARA MOVILES
  l-control.control-container.bgLeafletControl.hidden-lg-and-up(
    :style="{width: showTip === true ? 'map-control-opened' : 'map-control-closed'}"
    position="bottomright")
    .control-icon.py-1.px-1(
      v-if="!showTip",
      @click="toggleTip",
      style="cursor: pointer"
      title="Dibujar diagrama skewT"
    )
      v-icon(large) mdi-chart-box-outline
    .control-tip.scrollable.hidden-lg-and-up(v-else class="py-4 px-4 mx-1" id="box-small")
      .text-h5.mb-1
        | Sondeos
        v-btn(
          small
          icon="mdi-chevron-right"
          variant="text"
          elevation="0"
          density="comfortable"
          class="text-center float-right pa-2 my-1"
          @click="cancel")
          v-icon(large) mdi-chevron-double-down mdi-24px
      v-divider
      .my-2.control-internal-content
        | Seleccione un punto en el mapa para desplegar el sondeo
      v-divider(v-if="selectedPnt")
      .my-2(v-if="selectedPnt")
        div(class="text-subtitle-1 text--subtitleLeafletControl font-weight-light")
          | Punto seleccionado
        div {{selectedPnt.lat}} | {{selectedPnt.lng}}
      //- v-switch(
        class="mt-0"
        @click="switch = !switch"
        color="orange darken-3"
        title="Cambiar tipo de gráfica")
      forecast-variables(ref="variablesControl" class="variables-sondeos pt-0 pb-0")
      v-select(
        v-model="selectedDiagram"
        class="control-internal-content"
        small
        :items="diagrams"
        label="Seleccione tipo de gráfica"
        outlined
        dense)
      v-btn(
        v-if="currentAppRate.rate_id === 0 || currentAppRate.price === 0"
        rounded
        class="text-none text-subtitle-1 premium-button-lg"
        variant="elevated"
        color="white"
        :title="premiumTitle"
        @click="goPayment"
        outlined)
        v-icon mdi-medal
        <b>Plan Premium</b>
      v-divider(class="mt-3")
      span(v-if="errorMsg" class="mt-2 errorLefletControl--text control-error") {{errorMsg}}
      .mt-2.d-flex.control-internal-content
        v-row
          v-col(cols="12" md="6")
            v-btn(
              block
              small
              color="#00B0FF"
              class="text-subtitle-2 boton_control"
              @click="createSounding") Graficar
              v-icon(right dark small) mdi-chart-areaspline
</template>

<script>
/* eslint-disable no-undef */
import { mapState } from 'vuex';
import {
  LMarker,
  LControl,
} from 'vue2-leaflet';
import { icon } from 'leaflet';
import markerMixin from '@/mixins/markerMixin.js';
import lfControlMixin from '@/mixins/lfControlMixin.js';
import {
  serverApi,
  frontendRoute,
  premiumTitleText,
} from '../../config.js';
import { getToken } from '../../utils.js';

import ForecastVariables from '@/components/Forecast/ForecastVariables.vue';

export default {
  name: 'Soundings',
  components: {
    LControl,
    LMarker,
    ForecastVariables,
  },
  mixins: [markerMixin, lfControlMixin],
  data() {
    return {
      icon: icon({
        iconUrl: require('@/assets/marker-icon.png'),
        shadowUrl: require('@/assets/marker-shadow.png'),
        iconSize: [25, 37],
        iconAnchor: [16, 37]
      }),
      tab: 'Stuve',
      errorMsg: '',
      loaded: false,
      selectedPnt: null,
      surveyPnt: null,
      data: null,
      skData: null,
      diagrams: [
        'Stuve',
        'SkewT',
      ],
      selectedDiagram: null,
      disabledButton: false,
    };
  },
  computed: {
    ...mapState('gis', ['initialMapZoom', 'baseLayers', 'mapLat', 'mapLon']),
    ...mapState('ui/leaflet', ['netCDFPeriods', 'selectedPeriod']),
    currentGraph() {
      return `Diagrama: ${this.switch === true ? 'Stuve' : 'SkewT'}`;
    },
    premiumTitle() {
      return premiumTitleText;
    },
  },
  methods: {
    showSounding() {
      const selectedData = this.selectedDiagram === 'Stuve' ? this.data : this.skData;
      const selectedGraph = this.selectedDiagram === 'Stuve' ? 'stuveGraph' : 'skwetGraph';
      const selectedTitle = this.selectedDiagram === 'Stuve' ? 'Stuve' : 'SkewT';
      this.showForecast(
        selectedData,
        selectedGraph,
        `Diagrama ${selectedTitle} (${this.selectedPnt.lat} | ${this.selectedPnt.lng})`
      );
    },
    async loadDiagram() {
      // deshabilitar el boton de carga de datos
      this.disabledButton = true;
      const coords = this.mixinMarker.toGeoJSON().geometry.coordinates;
      // traer los valores de las variables extra si existen
      const extraParams = this.$refs.variablesControl.urlQueryParams();
      const queryParams = `?date=${this.selectedPeriod}&lat=${coords[1]}&lon=${coords[0]}${extraParams}`;
      this.loaded = false;

      // Conseguir el token si existe para autenticar la petición
      const token = await getToken();
      const headers = {};
      if (token !== '') {
        headers.Authorization = `Bearer ${token}`;
      }

      let response = null;
      if (this.selectedDiagram === 'Stuve') {
        console.log('entro grafico Stuve');
        response = await fetch(`${serverApi}${frontendRoute}/${this.controlModules.stuve}${queryParams}`, {
          headers
        });
        /* const graphData = {
          date: this.selectedPeriod,
          lat: coords[1],
          lon: coords[0]
        }; */
        if (response.status === 200) {
          const jsonResp = await response.json();
          // this.$bus.$emit('stuve-data-loaded', { data: jsonResp, ...graphData });
          this.data = jsonResp;
          this.errorMsg = '';
          this.showSounding();
        } else {
          const data = await response.json();
          this.$bus.$emit('stuve-no-load', { error: `${response.status} - ${data || response.statusText}` });
        }
      }

      if (this.selectedDiagram === 'SkewT') {
        console.log('entro grafico SkewT');
        response = await fetch(`${serverApi}${frontendRoute}/${this.controlModules.skewt}${queryParams}`, {
          headers
        });
        if (response.status === 200) {
          const jsonResp = await response.json();
          this.skData = jsonResp;
          // this.$bus.$emit('skewt-data-loaded', { data: jsonResp, ...graphData });
          // this.data = jsonResp;
          this.errorMsg = '';
          this.showSounding();
        } else {
          this.$bus.$emit('skewt-no-load', { error: `${response.status} - ${response.statusText}` });
        }
      }

      this.loaded = true;
      this.disabledButton = false;
    },
    cancel() {
      this.showTip = !this.showTip;
      this.errorMsg = '';
    },
    createSounding() {
      if (!this.selectedPnt) {
        this.errorMsg = 'Debe seleccionar un punto en el mapa';
        return;
      }

      if (!this.selectedPeriod) {
        this.errorMsg = 'Debe seleccionar un periodo de tiempo';
        return;
      }

      if (!this.selectedDiagram) {
        this.errorMsg = 'Debe seleccionar un diagrama';
        return;
      }

      // validar variables extra del modulo
      if (!this.$refs.variablesControl.validate()) {
        this.errorMsg = 'Hay variables que contienen errores';
        return;
      }

      // se obtinen los datos geoJSON de la polylinea
      console.log(this.mixinMarker.toGeoJSON());

      // consultar lod datos de los diagramas
      this.loadDiagram();
    },
    mapClick(e) {
      if (this.showTip) {
        const lat = e.latlng.lat.toFixed(6);
        const lng = e.latlng.lng.toFixed(6);
        this.selectedPnt = {
          lat,
          lng,
        };
      }
    },
  },
};
</script>

<style scoped>
.control-tip {
  padding: 22px !important;
}
#box-small .control-internal-content, .v-input__slot {
  padding-right: 0px !important;
}
.control-internal-content, .v-input__slot {
  padding-right: 64px !important;
}
.control-tip .v-input {
  padding-right: 0px !important;
}
</style>
