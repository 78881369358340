<template lang="pug">
l-map(
  :zoom="initialMapZoom",
  :center="{ lat: mapLat, lng: mapLon }",
  :options="mapOptions",
  :style="{ zIndex: 10, height: '100%', cursor: 'crosshair' }",
  ref="myMap",
  @click="mapClick",
  @ready="lefletMapReady()"
  @resize="height_screen",
  :height="height_map",
)
  l-control(
    id="loadingMap"
    v-if="loadingMap || loadingCanvas"
  )
    div(class="loading-map")
      bar-loader
  l-control-zoom(position="topright")
  lf-static-map-frame
  l-control(id="modeDark" position="topright")
    v-tooltip(top nudge-bottom="75")
      template(v-slot:activator="{ on, attrs }")
        v-btn(
          fab
          x-small
          class="mt-0"
          @click="toggleTheme"
          v-bind="attrs"
          v-on="on")
          v-icon(v-if="!$vuetify.theme.dark") mdi-weather-night
          v-icon(v-else) mdi-white-balance-sunny
      span Activar tema nocturno
  l-control(position="topright" class="radiolayer")
    v-menu(transition="slide-y-transition" class="container-layer")
      template(v-slot:activator="{ on, attrs }")
        v-btn(
          v-bind="attrs"
          v-on="on"
          fab
          x-small
          class="button-layer")
          v-icon mdi-layers mdi-24px
      v-card(class="menu-layer")
        v-container
          v-radio-group(
            v-model="selectedlayerId"
            @change="selectedlayer = baseLayers.find(l => l.id === selectedlayerId)")
            v-radio(
              v-for="layer in baseLayers" :key="layer.id" :label="layer.name" :value="layer.id")
  l-control-layers(ref="lyControl" :collapsed="collapsed" class="controlLayers")
  l-wms-tile-layer(
    :key="selectedlayer.name",
    :base-url="selectedlayer.url",
    :layers="selectedlayer.layers",
    :visible="selectedlayer.visible",
    :name="selectedlayer.name",
    :layer-type="selectedlayer.type",
    :transparent="selectedlayer.transparent",
    :format="selectedlayer.format",
    :updateWhenZooming=false
  )
  component(v-bind:is="mapControl" ref="mapControl" :control-modules="appModules")
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import L from 'leaflet';
import {
  LMap,
  LControlLayers,
  LWMSTileLayer,
  LControl,
  LControlZoom,
} from 'vue2-leaflet';
import LfStaticMapFrame from '@/components/Leaflet/LfStaticMapFrame.vue';
import WindRoseControl from '@/components/LeafletControls/WindRoseControl';
import MapSeriesControl from '@/components/LeafletControls/MapSeriesControl';
import SeriesControl from '@/components/LeafletControls/SeriesControl';
import SoundingsControl from '@/components/LeafletControls/SoundingsControl';
import WindPowerControl from '@/components/LeafletControls/WindPowerControl';
import CutsControl from '@/components/LeafletControls/CutsControl';
import SolarEnergyControl from '@/components/LeafletControls/SolarEnergyControl';
import { vueApps } from '@/config.js';
import BarLoader from '@/components/ui/BarLoader.vue';

export default {
  name: 'FrontEnd',
  components: {
    BarLoader,
    LMap,
    LControlLayers,
    LControl,
    LControlZoom,
    'l-wms-tile-layer': LWMSTileLayer,
    LfStaticMapFrame,
    WindRoseControl,
    MapSeriesControl,
    SeriesControl,
    SoundingsControl,
    WindPowerControl,
    CutsControl,
    SolarEnergyControl,
  },
  data() {
    return {
      loadingMap: true,
      isFirstLoad: true,
      selectedlayerId: null,
      selectedlayer: {
        name: '',
        url: '',
        layers: '',
        visible: true,
        type: 'wms',
        transparent: true,
        format: 'image/png'
      },
      collapsed: false,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false
      },
      layerControl: null,
      map: null,
      mapControl: '',
      baseLayer: null,
      mapLatValue: 0,
      mapLonValue: 0,
      height_map: window.innerHeight,
    };
  },
  computed: {
    ...mapState('meteocolombia', ['selectedCity', 'loadingCanvas']),
    ...mapState('gis', ['initialMapZoom', 'baseLayers', 'mapLat', 'mapLon', 'appModules']),
  },
  hasCanvas() {
    return this.$route.path.includes(['mapas_col', 'energy_col']);
  },
  watch: {
    // eslint-disable-next-line
    appModules(newValue, oldValue) {
      // Setear el nombre del control solo cuando cambie realmente el valor en el store
      this.getComponentName(this.$route.query.appType);
    },
    async $route(to, from) {
      this.loadingMapStart();
      // remover capas superpuestas cada vez que cambia de vista
      this.removeOverlayLayers();
      // cargar los datso de variables NETCDF si la applicación es diferente de maps
      // si es igual a maps se hará desde el compoenete MapSeriesControl
      await this.getAppParams(to.params.app);
      // obtener la variable inicial si se cambia de entre aplicaciones de tipo mapa
      if (to.query.appType === 'maps' && from.query.appType === 'maps') {
        this.SET_INITIAL_VARIABLE();
      }
      // this.getComponentName(this.$route.query.appType);
      // this.loadingMapEnd();
    },
    baseLayers(newValue) {
      if (newValue.length > 0) {
        // Seleccionar la última capa base
        this.selectedlayerId = newValue[newValue.length - 1].id;
        this.selectedlayer = newValue[newValue.length - 1];
      }
    },
    mapLat(newValue) {
      this.mapLatValue = newValue;
    },
    mapLon(newValue) {
      this.mapLonValue = newValue;
    },
    selectedCity(newValue) {
      if (newValue) {
        this.setCity();
      }
    },
    height_map(newValue) {
      console.log('height_map:', newValue);
    },
  },
  async mounted() {
    // obtener parametros del mapa(zoom, mapas base y punto central)
    // además de las variables y periodos que pueden ser consultadas
    await this.getAppParams(this.$route.params.app);
  },
  methods: {
    ...mapActions('ui/leaflet', ['getAppParams']),
    ...mapMutations('ui/leaflet', ['SET_INITIAL_VARIABLE']),
    message() {
      console.log('Message ...');
    },
    toggleTheme() {
      this.$vuetify.theme.themes.dark.anchor = '#41b883';
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    setCity() {
      this.map.setView([this.selectedCity.latitude, this.selectedCity.longitude], 10);
      // Dar click en el mapa para que se actualicen las variables
      this.$refs.mapControl.mapClick(
        {
          latlng: {
            lat: this.selectedCity.latitude, lng: this.selectedCity.longitude
          }
        }
      );
    },
    mapClick(e) {
      this.$refs.mapControl.mapClick(e);

      // Desactivar la interactividad del marcador si no es visible
      this.map.eachLayer((layer) => {
        if (layer instanceof L.Marker && layer.getLatLng().equals(e.latlng)) {
          if (!this.isMarkerVisible(layer)) {
            layer.setInteractive(false);
          }
        }
      });
    },
    getComponentName(appType) {
      const app = vueApps.filter((x) => x.name === appType)[0];
      const lfControl = app.component;
      this.mapControl = lfControl;
      return null;
    },
    async lefletMapReady() {
      this.map = this.$refs.myMap.mapObject;
      this.layerControl = this.$refs.lyControl.mapObject;
      this.map.on('zoomstart', this.loadingMapStart);
      this.map.on('zoomend', this.adjustLabelVisibility);
      this.map.on('movestart', this.loadingMapStart);
      this.map.on('moveend', this.loadingMapMoveEnd);
    },
    loadingMapStart() {
      this.loadingMap = true;
      console.log('loadingMapStart');
    },
    async loadingMapMoveEnd() {
      await new Promise((resolve) => setTimeout(resolve, 4000));
      this.loadingMap = false;
      console.log('loadingMapEnd');
    },
    async loadingMapEnd() {
      if (this.isFirstLoad) {
        console.log('FirstLoad');
        this.isFirstLoad = false;
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
      this.loadingMap = false;
      console.log('loadingMapEnd');
    },
    removeOverlayLayers() {
      console.log('removeOverlayLayers');
      const overlayLayers = this.layerControl._layers.filter((l) => l.overlay === true);
      // Eliminar las capas superpuestas si existen, del mapa y del control
      if (overlayLayers) {
        for (let index = 0; index < overlayLayers.length; index++) {
          const element = overlayLayers[index];
          this.map.removeLayer(element.layer);
          this.layerControl.removeLayer(element.layer);
        }
      }
    },
    adjustLabelVisibility() {
      const currentZoom = this.map.getZoom();
      this.map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          const markerElement = layer._icon;
          markerElement.classList.add('show-text');
          if (currentZoom < 7) {
            markerElement.classList.remove('show-text');
          }
        }
      });
      // this.loadingMapEnd();
    },
    height_screen() {
      this.height_map = window.innerHeight;
    },
  },
};
</script>

<style>
.leaflet-top {
  z-index: 1001 !important;
}
leaflet-top, .leaflet-right {
  margin-top: 40px;
}
.theme--dark .leaflet-control-zoom-in, .theme--dark .leaflet-control-zoom-out {
  background-color: #212121 !important;
  border-color: #212121 !important;
  color: #fff !important;
}
.theme--dark .leaflet-control-layers {
  background-color: #212121 !important;
  border-color: #212121 !important;
  color: #fff !important;
}
div.custom-marker {
  display: none;
}
.show-text div.custom-marker {
  display: inline-block;
}
.leaflet-control-layers {
  display: none !important;
}
.radiolayer{
  width: auto;
  height: auto;
  border: 2px solid transparent !important;
  border-radius: 5px !important;
}
v-input--radio-group__input, .v-radio{
  margin-bottom: 0px !important
}
.v-radio, .v-input, .v-label {
  font-size: 13px;
  color: #333;
  font-weight: 500;
}
.v-radio, .v-input, .v-input--selection-controls__input .v-icon {
  font-size: 16px;
}
.v-radio, .v-input, .v-input--selection-controls__input{
  margin-right: 0px !important
}
.container .v-input .v-input__control .v-messages{
  display: none !important;
}
.container .v-input--selection-controls{
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.container .v-input .v-input__control .v-input__slot{
  margin-bottom: 0px !important;
}
.button-layer{
  min-width: 30px !important;
}

.menu-layer{
  border-radius: 20px !important;
}
.leaflet-control-zoom {
  border: 2px solid transparent !important;
}
.leaflet-control-zoom-in{
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  color: #000000 !important
}
.leaflet-control-zoom-out{
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  color: #000000 !important
}
container-layer, .v-menu__content{
  border-radius: 20px !important;
}
.loading-map {
  align-items: left;
  display: flex;
  height: 100%;
  justify-content: left;
  left: 0px;
  pointer-events: none;
  position: fixed;
  top: 150px;
  transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), z-index 1ms;
  width: 100%;
  z-index: 6;
}
</style>
