<template lang="pug">
div(class="mt-8 text-center")
  v-card.py-5(
    max-width="400")
    v-list(
      subheader
      two-line)
      v-subheader(v-show="apps.length > 0") Aplicaciones
      v-subheader(class="text-center" v-show="apps.length == 0")
        v-row
          v-col(class="pt-4 pb-0" cols="12")
            v-icon(color="orange lighten-2" size="30") mdi-weather-lightning
          v-col(class="pt-0" cols="12")
            span No tiene créditos para Aplicaciones
      v-list-item(
        v-for="app in apps"
        :key="app.app__name")
        v-list-item-avatar
          v-icon(
            :title="app.category"
            :style="{backgroundColor: app.color}"
            dark) mdi-map
        v-list-item-content(class="d-flex flex-row")
          v-list-item-title(v-text="app.app__name")
        v-list-item-action(class="d-flex flex-row")
          span(class="text-h6 mr-1 grey--text text--darken-1") {{app.dcount}}
          span(class="meteo-coin")
            v-icon(color="orange lighten-2") mdi-weather-cloudy
  v-card.mt-5.py-5(
    max-width="400")
    v-list(
      subheader
      two-line)
      v-subheader(v-show="webgis.length > 0") Sigs Virtuales
      v-subheader(class="text-center pt-4" v-show="webgis.length == 0")
        v-row
          v-col(class="pt-0 pb-0" cols="12")
            v-icon(color="orange lighten-2" size="30") mdi-map-marker-remove
          v-col(class="pt-0" cols="12")
            span No tiene créditos para Sigs Virtuales
      v-list-item.px-5(
        v-for="sig in webgis"
        :key="sig.app__name")
        v-list-item-avatar
          v-icon(
            :title="sig.category"
            :style="{backgroundColor: sig.color}"
            dark) mdi-map-marker-multiple
        v-list-item-content
          v-list-item-title(v-text="sig.app__name")
          v-list-item-subtitle descripcion
        v-list-item-action(class="d-flex flex-row")
          span(class="text-h6 mr-1 grey--text text--darken-1") {{sig.dcount}}
          span(class="meteo-coin")
            v-icon(color="orange lighten-2") mdi-weather-cloudy
  v-btn(
    color="success"
    @click="goCart"
    class="mx-auto mt-3 white--text")
    v-icon(
      left
      dark) mdi-cart
    | Comprar más Créditos
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState
} from 'vuex';

export default {
  name: 'MeteoWallet',
  data: () => ({
  }),
  computed: {
    ...mapState('subscription', ['credits']),
    ...mapGetters('subscription', ['apps', 'webGis']),
    apps() {
      return this.credits.filter((x) => x.app__type__type !== 'webgis');
    },
    webgis() {
      return this.credits.filter((x) => x.app__type__type === 'webgis');
    }
  },
  watch: {
    '$store.state.ui.showDialogForm': {
      deep: true,
      handler(newVal) {
        if (newVal === true) {
          // generar el codigo de referencia
          this.getCredits();
        }
      }
    }
  },
  mounted() {
    this.getRates();
    this.getCredits();
  },
  methods: {
    ...mapActions('subscription', ['getRates', 'getCredits']),
    ...mapMutations('ui', ['SHOW_ALERT']),
    // metodo para ir al carro de compras
    goCart() {
      this.$bus.$emit('select-modal-form', {
        component: 'credit-manager',
        title: 'Adquiera créditos para las aplicaciones'
      });
    }
  },
};
</script>

<style scoped>
  .meteo-coin {
    padding: 5px;
    background: #FFD740;
    border-radius: 50%;
  }
</style>
